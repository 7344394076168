import axios from "axios";
import React, { useEffect, useState } from "react";
import "./herosectionMedia.css";
import { useNavigate } from "react-router-dom";
import Nav_Bar from "../../../components/nav-bar/Nav_Bar";

export const Hero_section = () => {
  const [content, setcontent] = useState([]);

  useEffect(() => {
    const analysis = async () => {
      try {
        const response = await axios.get(
          `https://rmoztec.com/api/public/api/headers`
        );
        console.log(response.data.data);
        setcontent(response.data.data);
      } catch (error) {
        console.error("Error fetching analysis data:", error);
      }
    };
    analysis();
  }, []);

  const push = useNavigate();

  return (
    <div className="herosectionContent">
      {/* Video Background */}
      {content.map((item) => (
        <video autoPlay muted loop playsInline className="video-background">
          <source src={item.viedo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      ))}

      {/* Content */}
      <Nav_Bar />
      <div className="content container">
        {content.map((item) => (
          <h3 className="mb-2" style={{ color: "#fff" }}>
            {item.title}
          </h3>
        ))}
        <p style={{ color: "#eee", fontSize: "20px" }}>
          {content.map((item) => (
            <span key={item.id}>{item.des}</span>
          ))}
        </p>
        <button
          className="mt-3 btn_hero_section_header"
          onClick={() => push("/contact-us")}
          style={{
            backgroundColor: "#3C096C",
            color: "#fff",
            padding: "10px 20px",
            border: "none",
            borderRadius: "20px",
            cursor: "pointer",
            width: "fit-content",
          }}
        >
          ابدا مشروعك الان
        </button>
      </div>
    </div>
  );
};

// <div className="analyses mt-3">
//   <h5 className="title mt-5" style={{ color: "#fff" }}>
//     ارقام توثق نجاحنا
//   </h5>
//   <div className="boxes d-flex gap-4 mt-3">
//     {numbers.map((item, index) => (
//       <div key={index}>
//         <h5 style={{ color: "#fff", fontSize: "30px" }} className="anayses fw-bolder">
//           {item.phone}+
//         </h5>
//         <p className="analyseseTitle" style={{color: "#3C096C"}}>{item.title}</p>
//       </div>
//     ))}
//   </div>
// </div>
