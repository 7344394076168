import React, { useEffect, useState } from "react";
import "./servsesStylesPage.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Nav_Bar from "../../components/nav-bar/Nav_Bar";

const Servses = () => {
  const [serv, setServ] = useState();
  useEffect(() => {
    const getAllData = async () => {
      try {
        const response = await axios.get(
          "https://rmoztec.com/api/public/api/services"
        );
        console.log(response.data.data);
        setServ(response.data.data);
      } catch (err) {
        console.log(err);
      }
    };
    getAllData();
  }, []);

  const push = useNavigate();
  return (
    <>
    <Nav_Bar backgroundColor={"#87098a"} />
      <div className="container">
        <h3 className=" text-center mt-5">خدماتنا</h3>
        <div className="boxes">
          {serv &&
            serv.map((item, index) => (
              <div className={`box_servses_section mt-5 pt-5 d-flex justify-content-between`} key={index}>
                <div className="content_serv">
                  <h4 className="title">{item.title}</h4>
                  <p> {item.des} </p>
                  <button
                    onClick={() => {
                      push("/contact-us");
                      window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                      });
                    }}
                    className="d-flex justify-content-center align-items-center mt-2 rounded-2 px-4 py-1 text-white"
                    style={{ backgroundColor: "#3C096C", border: "none" }}
                  >
                    {"اطلب الخدمه الان"}
                  </button>
                </div>
                <div className="img">
                  <img
                    className="imgageServsesStyles"
                    src={item.img}
                    alt=""
                  />{" "}
                </div>
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default Servses;