import axios from "axios";
import React, { useEffect, useState } from "react";

const Analyses = () => {
  // const ana = [
  //   { title: "سنوات الخبرة", count: 15 },
  //   { title: "موظف و موظفة", count: 99 },
  //   { title: "عميل راضي", count: 3500 },
  //   { title: "زيارة للموقع", count: "150K" },
  //   { title: "تطبيق ناجح", count: 15 },
  // ];

  const [numbers, setNumbers] = useState([])

  useEffect(() => {
    const analysis = async () => {
      try {
        const response = await axios.get(
          `https://rmoztec.com/api/public/api/numbers`
        );
        console.log(response.data.data);
        setNumbers(response.data.data)
      } catch (error) {
        console.error("Error fetching analysis data:", error);
      }
    };
    analysis();
  }, []);

  return (
    <div className="mt-5">
      <h6 className="title mb-5 mt-5 text-center " style={{ color: "#3C096C" }}>
        ارقام توثق نجاحنا
      </h6>
      <div className="analyses mt-3">
        <div className="boxes d-flex justify-content-around align-items-center text-center gap-4 mt-3">
          {numbers.map((item, index) => (
            <div key={index}>
              <h5 style={{ color: "#3C096C", fontSize: "20px" }} className="fw-bold">
                {item.phone}+
              </h5>
              <p>{item.title}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Analyses;
