import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

export const Partnar_section = () => {
  const [swiperInstance, setSwiperInstance] = useState(null);

  const handleSlideChange = (swiper) => {
    console.log("Slide changed to: ", swiper.activeIndex);
  };

  return (
    <div
      className="mt-5 d-flex justify-content-center align-items-center gap-3 overflow-hidden"
      style={{ position: "relative", height: "200px", width: "100%" }}
    >
      <div>
        <h5 className="text-center">نفتخر بالتعاون مع العملاء المميزين</h5>
        <Swiper
          spaceBetween={20}
          slidesPerView={6}
          onSlideChange={handleSlideChange}
          onSwiper={(swiper) => setSwiperInstance(swiper)}
          className="mySwiper mt-5 mx-3"
          style={{ width: "100%", maxWidth: "800px" }}
          loop={true}
        >
          <SwiperSlide className={`d-flex items-center rounded-5`}>
            <img src="/assets/partnars/Group 4.png" alt="" style={{ width: "100%" }} />
          </SwiperSlide>
          <SwiperSlide
            className={`d-flex items-center rounded-5`}
            style={{ background: "linear-gradient(to left, #ffffff, #F6F6F6)", width: "100px" }}
          >
            <img src="/assets/partnars/Group 5.png" alt="" style={{ width: "100%" }} />
          </SwiperSlide>
          <SwiperSlide
            className={`d-flex items-center rounded-5`}
            style={{ background: "linear-gradient(to left, #ffffff, #F6F6F6)", width: "100px" }}
          >
            <img src="/assets/partnars/Group 6.png" alt="" style={{ width: "100%" }} />
          </SwiperSlide>
          <SwiperSlide
            className={`d-flex items-center rounded-5`}
            style={{ background: "linear-gradient(to left, #ffffff, #F6F6F6)", width: "100px" }}
          >
            <img src="/assets/partnars/Group 7.png" alt="" style={{ width: "100%" }} />
          </SwiperSlide>
          <SwiperSlide
            className={`d-flex items-center rounded-5`}
            style={{ background: "linear-gradient(to left, #ffffff, #F6F6F6)", width: "100px" }}
          >
            <img src="/assets/partnars/Group 8.png" alt="" style={{ width: "100%" }} />
          </SwiperSlide>
          <SwiperSlide
            className={`d-flex items-center rounded-5`}
            style={{ background: "linear-gradient(to left, #ffffff, #F6F6F6)", width: "100px" }}
          >
            <img src="/assets/partnars/Group 9.png" alt="" style={{ width: "100%" }} />
          </SwiperSlide>
          <SwiperSlide
            className={`d-flex items-center rounded-5`}
            style={{ background: "linear-gradient(to left, #ffffff, #F6F6F6)", width: "100px" }}
          >
            <img src="/assets/partnars/Group 18.png" alt="" style={{ width: "100%" }} />
          </SwiperSlide>
          <SwiperSlide
            className={`d-flex items-center rounded-5`}
            style={{ background: "linear-gradient(to left, #ffffff, #F6F6F6)", width: "100px" }}
          >
            <img src="/assets/partnars/Group 19.png" alt="" style={{ width: "100%" }} />
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};