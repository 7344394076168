import { Route, Routes } from "react-router-dom";
import "./App.css";
import Footer from "./components/Footer copy/Footer";
import { Home } from "./pages/home/Home";
import { Page_not_found } from "./pages/error/Page_not_found";
import Terms from "./pages/privacy/Terms";
import { Contactus } from "./pages/contact-us/contactus";
import Project from "./pages/projects/Project";
import { HowWeAre } from "./pages/how-we-are/HowWeAre";
import Servses from "./pages/servses/Servses";
import Web from "./pages/web/Web";
import Company_sites from "./pages/company-webSites/Company_sites";
import Ecommerce_sites from "./pages/ecommerce-websites/ecommerce_sites";
import { useEffect, useState } from "react";

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000); // Set a delay of 2 seconds before setting isLoading to false
  }, []);

  if (isLoading) {
    return (
      <div
        className="loader-container d-flex justify-content-center align-items-center flex-column bg-black"
        style={{ height: "100vh" }}
      >
        <img src="/assets/logoRmoz.png" alt="rmoz logo" />

        <div className="loader">
          <svg class="pl" width="240" height="240" viewBox="0 0 240 240">
            <circle
              class="pl__ring pl__ring--a"
              cx="120"
              cy="120"
              r="105"
              fill="none"
              stroke="#000"
              stroke-width="20"
              stroke-dasharray="0 660"
              stroke-dashoffset="-330"
              stroke-linecap="round"
            ></circle>
            <circle
              class="pl__ring pl__ring--b"
              cx="120"
              cy="120"
              r="35"
              fill="none"
              stroke="#000"
              stroke-width="20"
              stroke-dasharray="0 220"
              stroke-dashoffset="-110"
              stroke-linecap="round"
            ></circle>
            <circle
              class="pl__ring pl__ring--c"
              cx="85"
              cy="120"
              r="70"
              fill="none"
              stroke="#000"
              stroke-width="20"
              stroke-dasharray="0 440"
              stroke-linecap="round"
            ></circle>
            <circle
              class="pl__ring pl__ring--d"
              cx="155"
              cy="120"
              r="70"
              fill="none"
              stroke="#000"
              stroke-width="20"
              stroke-dasharray="0 440"
              stroke-linecap="round"
            ></circle>
          </svg>
        </div>
      </div>
    );
  }

  return (
    <div className="App" dir="rtl">
      {/* <Nav_Bar /> */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/how-we-are" element={<HowWeAre />} />
        <Route path="/serv" element={<Servses />} />
        <Route path="/rmoz-projects" element={<Project />} />
        <Route path="/contact-us" element={<Contactus />} />
        <Route path="/Terms-and-conditions" element={<Terms />} />
        {/* start tecks */}
        <Route path={`ProjecysSection/:id`} element={<Web />} />
        <Route
          path="/company-portfolios-web-sites"
          element={<Company_sites />}
        />
        <Route path="/ecommerce-websites" element={<Ecommerce_sites />} />

        <Route path="*" element={<Page_not_found />} />
      </Routes>
      <Footer />
      <a href="https://wa.me/+966570869472" target="blank" className="contactWhatsApp">
        <img width={50} src="/assets/whatsapp.gif" alt="whatsapp" />
      </a>
    </div>
  );
}

export default App;

// <Route path="/custom_software" element={<Programming />} />
// <Route path="/Markting_" element={<Markteng />} /> */}
