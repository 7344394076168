import React from "react";
import "./banner_serv.css";
import { useNavigate } from "react-router-dom";

export const Banner_serv = () => {
  const push = useNavigate();
  return (
    <div className="bannerServ_contaioner d-flex mt-5 mb-5" style={{marginTop:"150px"}}>
      <div className="content" style={{marginTop:"100px"}}>
        <p className="m-0" style={{fontSize: "20px", fontWeight: "bold"}}>انتشر سريعاً وسط منافسيك</p>
        <p className="description_banner_serv" style={{fontSize: "20px", fontWeight: "bold"}}>
          انطلق نحو القمة وحقق النجاح في مجال التجارة الإلكترونية من خلال
          خدماتنا المتميزة والمتمثلة في تصميم المواقع والمتاجر الإلكترونية وكذلك
          تقديم أفضل خدمات التسويق الإلكتروني المتكاملة
        </p>
        <button
            onClick={() => {
              push("/contact-us");
              window.scrollTo({
                top: 0,
                behavior: "smooth", // Optional for a smooth scroll
              });
            }}
            className="d-flex justify-content-center align-items-center mt-2 rounded-2 px-4 py-1 text-white mt-5"
            onMouseEnter={(e) => e.target.style.backgroundColor = "#6a0572"}
            onMouseLeave={(e) => e.target.style.backgroundColor = "#87098A"}
            style={{
              backgroundColor: "#87098A",
              border: "none",
              borderRadius: "8px",
              color: "white",
              transition: "background-color 0.3s ease"
            }}>
          
            اطلب الخدمه الان
          </button>
      </div>
      <div className="image">
        <img className="imageBanner_serv_" src="/assets/banarServ.gif" alt="" />
      </div>
    </div>
  );
};
