import React from "react";
import { useNavigate } from "react-router-dom";

export const Page_not_found = () => {
  const push = useNavigate()
    return (
    <div className="h-screen w-screen bg-gray-100 d-flex flex-column justify-content-center align-items-center mt-5">
      <div className="container d-flex justify-content-between align-items-center px-5 text-gray-700">
        <div className="max-w-md">
          <div className="text-5xl font-bold">404</div>
          <p className="text-2xl md:text-3xl font-light leading-normal">
            عذراً، لم نتمكن من العثور على هذه الصفحة.
          </p>
          <p className="mb-8">
            نحن نعمل علي الموقع حاليا قم بالرجوع في وقت اخر عندما ننتهي من هذا
            الجزء من موقعنا
          </p>
          <p className="mb-8">نقد اهتمامك ونتمني من ان تنال ما تبحث عنه</p>
          <button
            className="px-4 py-2 text-sm font-medium leading-5 shadow text-white transition-colors duration-150 border border-transparent rounded-lg focus:outline-none focus:shadow-outline-blue bg-blue-600 active:bg-blue-600 hover:bg-blue-700"
            onClick={() => push("/")}
          >
            العودة إلى الصفحة الرئيسية
          </button>
        </div>
        <div class="max-w-lg">
          <img
            src="/assets/404.webp"
            class="img-fluid rounded-top"
            alt=""
            width={400}
          />
        </div>
      </div>
    </div>
  );
};
