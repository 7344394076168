import styles from "./footer.module.css";
import topLine from "./assets/topLine.svg";
import { useNavigate } from "react-router-dom";
import arrow from "./assets/arrow.svg";
import whatsApp from "./assets/logos_whatsapp-icon.png";
import email from "./assets/ic_baseline-mark-email-unread.png";
import { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";

const Footer = () => {
  const navigate = useNavigate();
  const scrollToTop = () => {
    navigate("/Terms-and-conditions");
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional for a smooth scroll
    });
  };

  const sitePages = [
    { title: "الرئيسيه", path: "/" },
    { title: "اعمالنا", path: "/rmoz-projects" },
    { title: "من نحن", path: "/how-we-are" },
    { title: "خدماتنا", path: "/serv" },
    { title: "اتصل بنا", path: "/contact-us" },
  ];

  const mainServices = [
    { title: "تصميم مواقع الشركات", path: "/" },
    { title: "التسويق الالكتروني", path: "/" },
    { title: "البرمجه الخاصه", path: "/" },
    { title: "فيديو موشن جرافيك", path: "/" },
    { title: "تصميم الهويه التجاريه", path: "/" },
    { title: "تصميم المتاجر الالكترونيه", path: "/" },
  ];

  const [data, setData] = useState([]);
  useEffect(() => {
    const getContent = async () => {
      try {
        const response = await axios.get(
          "https://rmoztec.com/api/public/api/contact-numbers"
        );
        setData(response.data.data);
      } catch (err) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      }
    };
    getContent();
  }, []);

  const [formData, setFormData] = useState({
    email: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const submit = async (e) => {
    e.preventDefault(); // Prevent page refresh
    try {
      const response = await fetch(
        "https://rmoztec.com/api/public/api/emails",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (!response.ok) {
        throw new Error(response.statusText);
      }

      Swal.fire({
        title: "احسنت",
        text: "هنبعتلك رسايل مهمه تهمك كل فتره",
        icon: "success",
      });

      setFormData({
        email: "ashraf@example.com",
      });
    } catch (err) {
      console.error("Error during submission:", err);
      alert("حدث خطأ أثناء إرسال النموذج. حاول مرة أخرى لاحقًا.");
    }
  };

  return (
    <div
      className={`${styles.Footer}`}
      style={{ backgroundColor: "#87098a", marginTop: "100px" }}
    >
      <div className="m-auto" style={{ width: "88%" }}>
        <header className={styles.footerHead}>
          <div className="intro">
            <img width={130} src={"/assets/logoRmoz.png"} alt="" />
            <div className="cont">
              <p className="m-0 text-white" style={{ marginRight: "10px" }}>
                المملكة العربية السعودية- الرياض- التحلية - بناية 502
                - الدور الثالث
              </p>
              <p className="m-0 mt-3 text-white">
                الإمارات العربية المتحدة- دبي - الخلية التجارية - بينونه
                تاور - الدور
              </p>
            </div>
            <div className="contactsContent">
              <div className="mt-5 d-flex align-items-center gap-2 mt-3">
                {data.map((item, index) => (
                  <div key={index} className="phones d-flex gap-3">
                    <p className="text-white m-0 p-0">{item.phone}</p>
                  </div>
                ))}
                <img src="/assets/mi_call.png" alt="Call" />
              </div>
              <div className="cont d-flex align-items-center gap-2 mt-1">
                <p className="text-white m-0 p-0">rmoztec0@gmail.com</p>
                <img src="/assets/mail.png" alt="Email" />
              </div>
            </div>
          </div>
          <div className={styles.pagesLinks}>
            <h5 className="mb-3 text-white">صفحات الموقع</h5>
            <div className="links d-flex flex-column">
              {sitePages.map((page, index) => (
                <div
                  key={index}
                  className="text-white text-decoration-none fw-bold gap-2 flex"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate(page.path);
                    window.scrollTo({ top: 0, behavior: "smooth" });
                  }}
                >
                  <img src={arrow} width={30} alt="" />
                  <span>{page.title}</span>
                </div>
              ))}
            </div>
          </div>
          <div className={styles.pagesLinks}>
            <h5 className="mb-4 text-white">اهم خدماتنا</h5>
            <div className="links d-flex flex-column">
              {mainServices.map((service, index) => (
                <div
                  key={index}
                  className="text-white text-decoration-none fw-bold gap-2 flex"
                >
                  <img src={arrow} width={30} alt="" />
                  <span>{service.title}</span>
                </div>
              ))}
            </div>
          </div>
          <div className="subScipe">
            <h5 className="mb-3 text-white">اشترك معنا لان</h5>
            <p
              className="description text-white fw-bold"
              style={{ width: "300px" }}
            >
              اشترك معنا فى النشرة الاخبارية ليصلك جديد اخبارنا وعروضنا باستمرار
            </p>
            <form onSubmit={submit} className="position-relative">
              <input
                type="email"
                name="email"
                id="email"
                placeholder="البريد الالكتروني"
                className="py-1 px-3 rounded-2"
                style={{ width: "310px", height: "50px", border: "none" }}
                value={formData.email}
                onChange={handleChange}
              />
              <button
                type="submit"
                className="position-absolute top-0 bg-transparent py-1 rounded-2"
                style={{
                  outline: "none",
                  left: "0",
                  height: "50px",
                  border: "none",
                  // backgroundColor: "var(--YColor)",
                  color: "white",
                }}
              >
                <img src={require("./Vector-footer.png")} alt="" />
              </button>
            </form>
            <p style={{ color: "var(--YColor)" }} className="mt-3 fw-bold">
              يسعدنا متابعتكم لنا
            </p>
          </div>
        </header>
        <div
          className={`${styles.bottomCopyRight} container flex items-center justify-between mt-2`}
          style={{ borderTop: "1px solid #7C077F" }}
        >
          <p className={`py-2 ${styles.copyRight}`}>
            © {new Date().getFullYear()} جميع الحقوق محفوظة لمؤسسة رموز
            لتكنولوجيا المعلومات
          </p>

          <p
            className={`py-2 ${styles.copyRight}`}
            style={{ cursor: "pointer", width: "fit-content" }}
            onClick={scrollToTop}
          >
            سياسة الخصوصية - الشروط والاحكام
          </p>
        </div>
        <img className={styles.topLine} src={topLine} alt="" />
      </div>
    </div>
  );
};

export default Footer;
