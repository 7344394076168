import React from "react";

const HeroSectionAbout = () => {
  return (
    <div className="d-flex justify-content-between align-items-center flex-wrap">
      <div className="text-center">
        <h6 className="mt-5 fs-5 mb-5" style={{ color: "#87098a" }}>
          مؤسسة رموز للتكنولوجيا والمعلومات
        </h6>
        <p style={{ color: "#3C096C", fontSize: "20px" }}>
          نوفر لك الحلول التقنية الأفضل لتحقيق النجاح الرقمي
        </p>
        <p style={{ color: "#3C096C", width: "100%", maxWidth: "500px", fontSize: "20px" }}>
          نحن فريق من الخبراء في البرمجة، التصميم، والتسويق الإلكتروني، نقدم لك
          خدمات مبتكرة ومتطورة تلبي احتياجاتك وتحقق أهدافك.اكتشف كيف يمكننا
          مساعدتك في تحقيق التفوق في عالم الإنترنت اليوم!
        </p>
      </div>
      <div className="image">
        <img src="/assets/aboutus0.gif" alt="" className="w-100" />
      </div>
    </div>
  );
};

export default HeroSectionAbout;
