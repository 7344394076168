import React, { useEffect, useState } from "react";
import "./formStyles.css";
import Swal from "sweetalert2";
import axios from "axios";
import Nav_Bar from "../nav-bar/Nav_Bar";

export const Form = () => {
  // State to handle form inputs
  const [formData, setFormData] = useState({
    title: "",
    email: "",
    phone: "",
    message: "",
    type_serv: "",
    salary: "",
  });

  // Update form state on change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Submit form data
  const submit = async (e) => {
    e.preventDefault(); // Prevent page refresh
    try {
      const response = await fetch(
        "https://rmoztec.com/api/public/api/contact_us",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (!response.ok) {
        throw new Error(response.statusText);
      }

      // alert('تم الإرسال بنجاح');
      Swal.fire({
        title: "احسنت",
        text: "استلمنا رسالتك هنتواصل معاك قريب 😍",
        icon: "success",
      });

      // Reset all inputs
      setFormData({
        title: "",
        email: "",
        phone: "",
        message: "",
        type_serv: "",
        salary: "",
      });
    } catch (err) {
      console.error("Error during submission:", err);
      alert("حدث خطأ أثناء إرسال النموذج. حاول مرة أخرى لاحقًا.");
    }
  };

  return (
    <>
      <div
        className="contactForm mt-5 container d-flex justify-content-between align-items-center"
        style={{ marginBottom: "150px" }}
      >
        <div className="img">
          <img
            className="imageContactForm mt-5 d-flex m-auto"
            width={400}
            src="/assets/contact.png"
            alt="Contact"
          />
        </div>
        <div>
          <h6 className="titleForm" style={{ color: "#3C096C" }}>
            تواصل معنا
          </h6>
          <p className="w-75">
            دعنا نكون شركاء نجاحك. إذا كان لديك استفسارات أو تحتاج إلى مزيد من
            المعلومات، اكتب لنا الآن وسنقوم بالرد في أقرب وقت ممكن!
          </p>
          <form className="form mt-5" onSubmit={submit}>
            <div className="input-dev d-flex flex-column gap-1 mt-3">
              <label htmlFor="title">الاسم</label>
              <input
                type="text"
                name="title"
                placeholder="يرجي كتابه الاسم هنا"
                value={formData.title}
                onChange={handleChange}
                className="rounded-3 py-1 px-2 fs-5"
                style={{ outline: "none", border: "2px solid #3C096C" }}
              />
            </div>
            <div className="input-dev d-flex flex-column gap-1 mt-3">
              <label htmlFor="email">البريد الالكتروني</label>
              <input
                type="email"
                name="email"
                placeholder="يرجي كتابه البريد الاكتروني هنا"
                value={formData.email}
                onChange={handleChange}
                className="rounded-3 py-1 px-2 fs-5"
                style={{ outline: "none", border: "2px solid #3C096C" }}
              />
            </div>
            <div className="input-dev d-flex flex-column gap-1 mt-3">
              <label htmlFor="phone">رقم الجوال</label>
              <input
                type="text"
                name="phone"
                placeholder="+09660541645465"
                value={formData.phone}
                onChange={handleChange}
                className="rounded-3 py-1 px-2 fs-5"
                style={{ outline: "none", border: "2px solid #3C096C" }}
              />
            </div>
            <div className="input-dev d-flex flex-column gap-1 mt-3">
              <label htmlFor="type_serv">اختر الخدمه</label>
              <select
                name="type_serv"
                value={formData.type_serv}
                onChange={handleChange}
                className="rounded-3 py-1 px-2 fs-5"
                style={{ outline: "none", border: "2px solid #3C096C" }}
              >
                <option value="" disabled>
                  اختر الخدمة
                </option>
                <option value="تصميم مواقع الشركات">تصميم مواقع الشركات</option>
                <option value="التسويق الالكتروني">التسويق الالكتروني</option>
                <option value="البرمجه الخاصه">البرمجه الخاصه</option>
                <option value="فيديو موشن جرافيك">فيديو موشن جرافيك</option>
                <option value="تصميم الهويه التجاريه">
                  تصميم الهويه التجاريه
                </option>
                <option value="تصميم المتاجر الالكترونيه">
                  تصميم المتاجر الالكترونيه
                </option>
              </select>
            </div>
            <div className="input-dev d-flex flex-column gap-1 mt-3">
              <label htmlFor="salary">السعر</label>
              <input
                type="text"
                name="salary"
                placeholder="كم تتوقع تكلفه الخدمه"
                value={formData.salary}
                onChange={handleChange}
                className="rounded-3 py-1 px-2 fs-5"
                style={{ outline: "none", border: "2px solid #3C096C" }}
              />
            </div>
            <button
              type="submit"
              className="d-flex justify-content-center align-items-center mt-2 rounded-2 px-4 py-1 text-white"
              onMouseEnter={(e) => e.target.style.backgroundColor = "#6a0572"}
              onMouseLeave={(e) => e.target.style.backgroundColor = "#87098A"}
              style={{
                backgroundColor: "#87098A",
                border: "none",
                borderRadius: "8px",
                color: "white",
                transition: "background-color 0.3s ease"
              }}>
            
              اطلب الخدمه الان
            </button>
          </form>
        </div>
      </div>
    </>
  );
};
