import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export const Projects = () => {
  const [projects, setProjects] = useState([]);
  useEffect(() => {
    const getProjects = async () => {
      try {
        const response = await axios.get(
          "https://rmoztec.com/api/public/api/projects"
        );
        console.log("projects",response.data.data);
        setProjects(response.data.data);
      } catch (err) {
        console.log(err);
      }
    };
    getProjects();
  }, []);

  const push = useNavigate();
  return (
    <div className="mb-5" style={{ marginTop: "90px" }}>
      <div className="title d-flex align-items-center justify-content-between">
        <h3>المشاريع</h3>
        <p
          className=" text-primary"
          style={{ cursor: "pointer" }}
          onClick={() => {
            push("/rmoz-projects");
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          }}
        >
          عرض الكل
        </p>
      </div>
      <div
        className="boxes mt-5"
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
          gap: "50px",
        }}
      >
        {projects.map((item) => (
          <div className="box position-relative mb-3" 
          onClick={() => window.open(item.link, '_blank')}>
             {/* ? item.image : "/assets/projects/project1.png" */}
            <img style={{ width: "100%" }} src={item.image} alt="" />
            <div
              className="content w-75 m-auto bg-white position-absolute py-2 px-1 text-center rounded-3 shadow-lg"
              style={{
                left: "50%",
                height: "100px",
                transform: "translateX(-50%)",
                bottom: "-40px",
              }}
            >
              <h5 style={{ color: "#3c096c" }}>{item.address.slice(0, 30)}..</h5>
              <p style={{ color: "#3c096c" }}>{item.description.slice(0, 40)}...</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
