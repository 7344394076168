import React, { useEffect, useState } from "react";
import "./webProjects.css";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import Nav_Bar from "../../components/nav-bar/Nav_Bar";

const Web = () => {
  const [data, setData] = useState([]);
  const [title, setTitle] = useState([]);
  
  const { id } = useParams();

  useEffect(() => {
    const getProjects = async () => {
      try {
        const response = await axios.get(
          `https://rmoztec.com/api/public/api/services/${id}`
        );
        setData(response.data.data.projects);
        setTitle(response.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getProjects();
  }, [id]);
  const push = useNavigate();
  return (
    <>
    <Nav_Bar backgroundColor={"#87098a"} />
    <div className="mt-5 container">
      <h4 className="text-center">{title.title}</h4>
      <div className="projectsBoxes mt-5">
        {data.length > 0 ? (
          data.map((item) => (
            <div className="projectBox_Content mt-5 pt-5 d-flex justify-content-between align-items-center gap-5">
              <div className="content">
                <h4 className="textProjectTitle mb-3"> {item.addr} </h4>
                <p className="text w-75">{item.des}</p>
                <div className="btnsProjects w-75 d-flex gap-2 justify-content-between align-items-center">
                  <button
                    onClick={() => {
                      push("/contact-us");
                    }}
                    className="btnProjectContent d-flex justify-content-center align-items-center mt-2 rounded-2 px-4 py-1 text-white"
                    style={{ backgroundColor: "#3C096C" }}
                  >
                    اطلب الخدمه الان
                  </button>
                  <button
                    onClick={() => {
                      window.open(item.link, "_blank");
                    }}
                    className="btnProjectContent d-flex justify-content-center align-items-center mt-2 rounded-2 px-4 py-1 text-white"
                    style={{ backgroundColor: "#3C096C" }}
                  >
                    عرض الموقع
                  </button>
                </div>
              </div>
              <div className="img">
                <img
                  width={400}
                  className="projectBox_ContentImg"
                  src="/assets/web/Rectangle 102.png"
                  alt=""
                />
              </div>
            </div>
          ))
        ) : (
          <div className="text-center mt-5">
            <p>نحن نعمل علي هذه الصفحه حاليا</p>
            <p>لم نقم باضافه المشاريع الخاصه بهذا القسم حتي الان</p>
            <p>comming soon</p>
          </div>
        )}
      </div>
    </div>
    </>
  );
};

export default Web;
