import React from "react";

const TellMeMore = () => {
  return (
    <div className=" container" style={{ marginTop: "80px" }}>
      <h5 className="mb-4 title text-center" style={{ color: "#3C096C" }}>
        <p>مهمتنا ورؤيتنا وقيمنا</p>
        <p>تعرف علينا اكثر</p>
      </h5>

      <div className="contentInfo d-flex justify-content-between align-items-center flex-wrap">
        <div className="boxes d-flex flex-wrap">
          <div className="box m-4">
            <h4 style={{ color: "#3C096C" }}>رؤيتنا</h4>
            <p className="w-100 p-4" style={{ marginRight: "20px" }}>
              نسعى لأن نكون الشريك الموثوق به لعملائنا في عالم الإنترنت، من خلال
              تقديم خدمات مبتكرة تتجاوز التوقعات وتساهم في تعزيز تواجدهم الرقمي
              وتمكينهم من التفوق على منافسيهم.
            </p>
          </div>
          <div className="box m-4">
            <h4 style={{ color: "#3C096C" }}>رسالتنـــــــا</h4>
            <p className="w-100 p-4" style={{ marginRight: "20px" }}>
              هدفنا هو تقديم قيمة حقيقية لعملائنا عبر تصميم مواقع إلكترونية ذات
              جودة عالية وسهلة الاستخدام، مع تعزيز وجودهم الرقمي من خلال حملات
              تسويقية فعالة وموجهة. نسعى لتحقيق النجاح المشترك بأسعار تنافسية
              وخدمات متميزة.
            </p>
          </div>
          <div className="box m-4">
            <h4 style={{ color: "#3C096C" }}>قيمنــــــــــا</h4>
            <p className="w-100 p-4" style={{ marginRight: "20px" }}>
              نلتزم بأعلى معايير الاحترافية في جميع أعمالنا، لضمان تقديم خدمات
              تتسم بالجودة والدقة. ونؤمن بأن الابتكار والإبداع هما مفتاح النجاح
              في عالم التكنولوجيا المتسارع. ونعمل بروح الفريق الواحد لنحقق
              الأهداف المشتركة بأفضل صورة ممكنة..
            </p>
          </div>
        </div>
      </div>
      {/*  */}
    </div>
  );
};

export default TellMeMore;
