import React, { useEffect, useState } from "react";
import "./offerBannerStyles.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";

export const Offer_banner = () => {

  // 

  const [data, setdata] = useState([]);

  useEffect(() => {
    const analysis = async () => {
      try {
        const response = await axios.get(
          `https://rmoztec.com/api/public/api/offers`
        );
        console.log(response.data.data);
        setdata(response.data.data);
      } catch (error) {
        console.error("Error fetching analysis data:", error);
      }
    };
    analysis();
  }, []);

  const push = useNavigate();
  
  return (
    <div
      className="BannerOfferSectoinPage bg-white d-flex justify-content-between align-items-center shadow-lg rounded-4"
      style={{ padding: "0 20px 0 0", maxWidth: "100%", height: "300px" }}
    >
      <div className="content pt-2 text-center">
        <h6 style={{ fontSize: "25px" }}>عرض لفترة محدودة</h6>
        <p className="mt-1" style={{ maxWidth: "100%" }}>
          عروض خاصة ولفترة محدودة علي تطوير المواقع
        </p>
        {data.map((item) => (
          <div
            key={item.id}
            className="offerBox mt-1 p-2 px-4 rounded-4"
            style={{
              backgroundColor: "#87098A",
              maxWidth: "100%",
              width: "100%",
            }}
          >
            <div className="title fs-5 text-white mt-1 fw-bolder mb-3">
              {item.title}
            </div>
            <div className="present d-flex align-items-center justify-content-center">
              <p
                className="past p-1 d-flex text-decoration-line-through justify-content-center align-items-center w-100 py-2 fs-6 text-white fw-bolder"
                style={{ background: "#AE7EC8", width: "100%" }}
              >
                {item.original_price} ريال
              </p>
              <p
                className="current p-1 d-flex justify-content-center align-items-center w-100 py-2 fs-6 fw-bolder"
                style={{
                  backgroundColor: "#F6F6F6",
                  color: "#adadad",
                  width: "100%",
                }}
              >
                {item.discounted_price} ريال
              </p>
            </div>
          </div>
        ))}
        {data.map((item) => (
          <button
            key={item.id}
            onClick={() => {
              window.open(item.link, "_blank");
            }}
            className="mb-1 d-flex justify-content-center align-items-center rounded-2 px-1 py-1 fs-4 mt-2 text-white w-75 m-auto"
            onMouseEnter={(e) => e.target.style.backgroundColor = "#6a0572"}
            onMouseLeave={(e) => e.target.style.backgroundColor = "#87098A"}
            style={{
              width: "60%",
              margin: "auto",
              backgroundColor: "#87098A",
              border: "none",
              borderRadius: "8px",
              color: "white",
              transition: "background-color 0.3s ease"
            }}>
          
            ابدا مشروعك
          </button>
        ))}
      </div>
      <div className="image top-0 h-100">
        <img
          src="/assets/offerBanner.png"
          className="rounded-4"
          alt=""
          style={{ width: "100%", height: "100%" }}
        />
      </div>
    </div>
  );
};
