import React from "react";
import { Hero_section } from "./hero-section/hero_section";
import { Form } from "../../components/contect-form/Form";
import { Servses } from "./servses/Servses";
import { Partnar_section } from "./bartnar_section/Partnar_section";
import { Projects } from "./Projects/Projects";
import { Banner_serv } from "./banner-serv/Banner_serv";
import { Offer_banner } from "./offer-banner/Offer_banner";

export const Home = () => {
  return (
    <>
      <Hero_section />
      <div className="container">
        <Servses />
        <Projects />
        <Banner_serv />
        <Offer_banner />
        <Form />
        <Partnar_section />
      </div>
    </>
  );
};
