import React from "react";

const Terms = () => {
  return (
    <div className="mt-5 pt-5 w-75 m-auto">
      <h3 className="title text-center">الشروط والاحكام</h3>
      <p className="desc mt-4" style={{ fontSize: "14px" }}>
        تعلن مؤسسة رموز الرقمية لتقنية المعلومات المالكة لموقع كود تايم أنها غير
        مسئولة عن إضافة أي محتوى بداخل المواقع ويعد العميل هو المسؤول الوحيد
        مسئولية كاملة أمام أي جهة حكومية أو قانونية، وكذلك تعد المؤسسة غير
        مسئولة عن الأضرار الناتجة لتوقف الخدمة لدى العميل، فالمؤسسة توفر كافة
        الخدمات المتاحة لتقديم أفضل خدمة للعملاء ولها المسئولية الكاملة عن الشق
        المهني الخاص بالعمل التقني ومعالجة أي أخطأ حال ظهورها. وكذلك الاستضافة
        المقدمة من رموز رقمية تتم وفقًا للمعايير الخاصة بالتكنولوجيا الحديثة،
        كما توضح أنها غير مسئولة عن عمليات النسخ الاحتياطي الخارجية من السيرفرات
        الخاصة، وكذلك في حالة وجود أي تلف خاص بالمعلومات تقوم المؤسسة بتقديم
        سيرفر آخر مماثل له نفس المواصفات خلال فترة زمنية محددة لا تتعدى خمسة
        أيام، وأخيرًا المؤسسة غير مسئولة عن حل أي مشاكل موجودة في تصميمات منسوبة
        لشركات أخرى وغير مصممة من قبل فريق عمل مؤسسة وقت البرمجة لتقنية
        المعلومات المالكة لموقع رموز الرقمية
      </p>
      <ul className="mt-5 pt-2">
        <li style={{ listStyle: "disc" }}>
          تهتم مؤسسة رموز الرقمية لتقنية المعلومات المالكة لموقعرموز الرقمية
          بسياسة الاستخدام العادل في الخدمات المقدمة من خلال الشبكة ولها الحق في
          اتخاذ كافة الإجراءات ضد أي مخالفة تؤثر على جودة الخدمة المقدمة من خلال
          المؤسسة ولها العديد من الطرق لمعرفة حجم الاستهلاك والحد المسموح به.
        </li>
        <li style={{ listStyle: "disc" }}>
          غير مسموح بمخالفة الشريعة الإسلامية وكذلك عدم مخالفة قانون الدولة بلد
          الإقامة ووضع أي محتوى مخل بالآداب العامة، وفي حال ثبوت أي مخالفة من حق
          رموز الرقمية وقف الخدمة المقدمة دون الأشعار أو الرجوع إلي العميل صاحب
          الخدمة ولا يحق للعميل الحصول على أي تعويض نتيجة عدم الالتزام بالقواعد
          المحددة من قبل رموز الرقمية.
        </li>
        <li style={{ listStyle: "disc" }}>
          الالتزام بدفع كافة الرسوم المحددة مقابل التجديد قبل انتهاء فترة السماح
          وإثبات ما يفيد بإتمام عملية التسديد وإخطار الدعم الفني بذلك.
        </li>
        <li style={{ listStyle: "disc" }}>
          عدم مسؤوليةرموز الرقمية بإبرام أي اتفاق خارج نطاق وسائل الاتصال
          المحددة من قبل المؤسسة والموضحة في أرقام الهواتف المخصصة للمؤسسة وكذلك
          البريد الإلكتروني المعلن عنه من قبل رموز الرقمية .
        </li>
        <li style={{ listStyle: "disc" }}>
          في حالة التأخير في سداد الرسوم يتم إرسال التنبيهات تلقائيًا إلى العميل
          عبر البريد الإلكتروني الخاص به، وفي حالة عدم الالتزام بالدفع يتم إيقاف
          الخدمة تلقائيًا وعدم تشغيلها إلا بعد سداد قيمة الرسوم، والمؤسسة غير
          مسؤولة عن انقطاع الخدمة في حال تأخير السداد.
        </li>
        <li style={{ listStyle: "disc" }}>
          تسجيل جميع الرسائل والمكالمات لضمان تقديم أفضل خدمة والرجوع إليها عند
          الحاجة لذلك.
        </li>
        <li style={{ listStyle: "disc" }}>
          يوجد العديد من المزايا المجانية غير قابلة للاستبدال والحصول على مقابل
          مادي بديل لها مثل رسائل الجوال، المحادثات المباشرة وغيرها من المزايا
          والإضافات المختلفة.
        </li>
        <li style={{ listStyle: "disc" }}>
          عند طلب العميل إعادة فتح حسابه مرة أخرى يتم قبول ذلك بعد سداد كافة
          الرسوم المستحقة، أما إذا طلب العميل نسخ ملفاته بعد انتهاء الفترة
          المحددة تقوم المؤسسة بطلب سداد رسوم مالية مقابل الخدمة.
        </li>
        <li style={{ listStyle: "disc" }}>
          عند وقف حساب العميل والخدمة المقدمة له لقيامه ببعض المخالفات وعدم
          الالتزام بالشروط وحال طلبه لتفعيل الحساب مرة أخرى يتم التعهد بالتزام
          وعدم المخالفة مرة أخرى .
        </li>
        <li style={{ listStyle: "disc" }}>
          يتم وقف الدعم الفني الخاص برموز الرقمية في حال عدم الالتزام ومخالفة
          الشروط والأحكام وإرسال رسائل تنبيه بوقف الدعم الفني للخدمة قبل انتهاء
          التجديد بـ 48 ساعة ويتم التأكد من العميل في حال طلب إلغاء الخدمة.
        </li>
        <li style={{ listStyle: "disc" }}>
          المؤسسة غير مسئولة عن دفع تكاليف الضرائب أو التحويلات المخصومة من
          تكلفة المشروع والعميل مطالب بتحمل هذه التكلفة. .
        </li>
        <li style={{ listStyle: "disc" }}>
          في حالة عدم رضاء العميل عن الاستضافة يحق للعميل استرداد المبلغ خلال
          فترة محددة وهي خمسة عشر يومًا .
        </li>
        <li style={{ listStyle: "disc" }}>
          عدم الإساءة لفريق العمل عند إبداء العميل لأي من الملاحظات أو التقليل
          من شأن المؤسسة والعاملين بها وعلى المؤسسة اتخاذ كافة الإجراءات
          القانونية حيال أي انتهاك لحماية حقوق المؤسسة المادية والأدبية.
        </li>
        <li style={{ listStyle: "disc" }}>
          يرجى من العميل الالتزام بدفع المبالغ المتفق عليها مقابل تقديم الخدمة
          في الحسابات المخصصة لمؤسسة وقت البرمجة لتقنية المعلومات المالكة لموقع
          كود تايم أو كاش أو بشيكات بنكية باسم رموز الرقمية.
        </li>
        <li style={{ listStyle: "disc" }}>
          بعد الانتهاء من كافة الإجراءات وتحرير العقد للحصول على الخدمة المحددة
          من قبل العميل تعد موافقة كاملة على كافة الشروط والمواصفات المتفق عليها
          بين كلا الطرفين ومذكورة في بنود العقد، ولا يعتد بأي بنود أخرى غير متفق
          عليها في بنود العقد المبرم.
        </li>
        <li style={{ listStyle: "disc" }}>
          يتم تغيير الأسعار مقابل توفير الخدمات المقدمة من المؤسسة أو الاستضافات
          ويتم ذلك بناءً على الوقت المراد التجديد فيه أو طلب خدمة جديدة.
        </li>
        <li style={{ listStyle: "disc" }}>
          لا بد من التزام العميل بجميع الإجراءات المطلوبة من قبل المؤسسة مقدمة
          الخدمة أو شركات الشحن أو الشركات التي يتم من خلالها رفع التطبيقات مثل
          آب ستور أو جوجل بلاي للقيام برفع التطبيقات مقابل رسوم محددة.
        </li>
        <li style={{ listStyle: "disc" }}>
          لا يحق للعميل صاحب الخدمة إزالة الحقوق الخاصة بملكية التصميم الخاص
          مؤسسة رموز الرقمية لتقنية المعلومات المالكة لموقع رموز الرقمية إلا
          باتفاق مسبق مع المؤسسة مقابل رسوم إضافية.
        </li>
        <li style={{ listStyle: "disc" }}>
          المؤسسة تقدم دعم فني مستمر بدون أي رسوم إضافية، كما توفر حماية كاملة
          ضد الاختراق والحفاظ على الأمان والخصوصية.
        </li>
        <li style={{ listStyle: "disc" }}>
          تعمل المؤسسة على حل كافة المشاكل من قبل الدعم الفني مثل إعادة كلمة
          المرور، النسخ الاحتياطي، المشاكل الايميل، الاتصال الآمن بين العميل
          والموقع.
        </li>
        <li style={{ listStyle: "disc" }}>
          في حال تغيير التصميم أو البرمجة دون الرجوع إلى الدعم الفني في ذلك
          الوقت المؤسسة غير مسئولة عن إعادة التصميم لوضعه الأصلي إلا في حال دفع
          رسوم جديدة مقابل ذلك.
        </li>
      </ul>
    </div>
  );
};

export default Terms;
