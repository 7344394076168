import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export const Servses = () => {
  const push = useNavigate();
  const [data,setData]=useState([])
  useEffect(() => {
    const getAllData = async () => {
      try {
        const response = await axios.get(
          "https://rmoztec.com/api/public/api/services"
        );
        console.log(response.data.data);
        setData(response.data.data)
      } catch (err) {
        console.log(err);
      }
    };
    getAllData();
  }, []);

  return (
    <div className="mt-5">
      <div className="title d-flex align-items-center justify-content-between">
        <h3>خدماتنا</h3>
        <button className="btn btn-primary"
         style={{ cursor: "pointer" }} onClick={() => { push("/serv");
          window.scrollTo({top: 0, behavior: "smooth" })}}>
          اعرف اكتر عن خدماتنا
        </button>
      </div>
      <div
        className="boxes mt-3"
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fill, minmax(320px, 1fr))",
          gap: "50px",
        }}
      >
        {data.map((item) => (
        <div className="box d-flex flex-column gap-4">
          <img
            style={{ width: "80%", height: "300px", margin: "auto" }}
            src={item.img}
            alt=""
          />
          <button onClick={() => {
              push(`/ProjecysSection/${item.id}`);
              window.scrollTo({
                top: 0,
                behavior: "smooth", // Optional for a smooth scroll
              });
            }}
            className="py-2 btn-hover"
            onMouseEnter={(e) => e.target.style.backgroundColor = "#6a0572"}
            onMouseLeave={(e) => e.target.style.backgroundColor = "#87098A"}
            style={{
              width: "60%",
              margin: "auto",
              backgroundColor: "#87098A",
              border: "none",
              borderRadius: "8px",
              color: "white",
              transition: "background-color 0.3s ease"
            }}>
           {item.title}
          </button>
        </div>
      ))}
      </div>
    </div>
  );
};