import axios from "axios";
import React, { useEffect, useState } from "react";
import Nav_Bar from "../../components/nav-bar/Nav_Bar";

const Project = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    const getProjects = async () => {
      try {
        const response = await axios.get(
          "https://rmoztec.com/api/public/api/projects"
        );
        setData(response.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getProjects();
  }, []);
  return (
    <>
        <Nav_Bar backgroundColor={"#87098a"} />
      <div className="mt-5 container">
        <h3 className="text-center">اعمالنا</h3>
        <p className="text-center mt-5 w-75 m-auto">
          احصل على خدمات برمجة وتصميم مواقع إلكترونية شاملة وابدأ في توسيع نطاق
          نشاطك لتكون الأفضل على مستوى العالم. ونحن نسعى جاهدين لنكون وجهتك
          الأولى لتصميم موقعك الإلكتروني، حيث نقدم لك خدمات برمجة وتصميم مواقع
          احترافية من خلال أفضل المبرمجين ذوي الخبرة والكفاءة، لتضمن لك الثقة
          وجودة الخدمة في آن واحد وسرعة التنفيذ.
        </p>

        <div className="projects mt-5">
          {data.map((item) => (
            <div className="project text-center mb-5">
              <img
                src={item.image}
                alt=""
                className="w-75"
              />
              <h5 className="">{item.address}</h5>
              <div className="d-flex justify-content-center align-items-center gap-4">
                <button
                  
                  onClick={() => window.open(item.link, '_blank')}
                  className="d-flex justify-content-center align-items-center mt-2 rounded-2 px-4 py-1 fs-4 mt-5 text-white"
                  style={{ backgroundColor: "#87098A", border: "none" }}
                >
                  عرض الخدمه
                </button>
                <button
                  type="submit"
                  className="d-flex justify-content-center align-items-center mt-2 rounded-2 px-4 py-1 fs-4 mt-5 text-white"
                  style={{ backgroundColor: "#87098A", border: "none" }}
                >
                  طلب الخدمه
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Project;
