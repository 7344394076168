import React from "react";
import "./bannerHowWeAre.css";
import { useNavigate } from "react-router-dom";
export const BannerHowWe = () => {
  const push = useNavigate();
  return (
    <div className="bannerHowWeAre d-flex justify-content-between align-items-center" style={{marginTop: "120px"}}>
      <div>
        <p className="title">انتشر سريعاً وسط منافسيك</p>
        <p className="description w-50">
          انطلق نحو القمة وحقق النجاح في مجال التجارة الإلكترونية من خلال
          خدماتنا المتميزة والمتمثلة في تصميم المواقع والمتاجر الإلكترونية وكذلك
          تقديم أفضل خدمات التسويق الإلكتروني المتكاملة
        </p>
        <button
        onClick={() => push("/contact-us")}
          className="btnHowWeAre d-flex justify-content-center align-items-center mt-2 rounded-2 px-4 py-1 text-white"
          style={{ backgroundColor: "#87098A", border: "none" }}
        >
          {" ابدا مشروعك "}
        </button>
      </div>
      <div className="imageHowWeAreBanner">
        <img width={300}  src="/assets/how We are doing banner.gif" alt="" />
      </div>
    </div>
  );
};
