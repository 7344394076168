import React from "react";
import HeroSectionAbout from "./heroSection/HeroSectionAbout";
import Analyses from "./analyses/Analyses";
import TellMeMore from "./tell-me-more/TellMeMore";
import { BannerHowWe } from "./bannerHowWe/bannerHowWe";
import Nav_Bar from "../../components/nav-bar/Nav_Bar";

export const HowWeAre = () => {
  return (
    <>
    <Nav_Bar backgroundColor={"#87098a"} />
    <div className="container">
      <HeroSectionAbout />
      <Analyses />
      <TellMeMore />
      <BannerHowWe />
    </div>
    </>
  );
};