import React from "react";
import { Form } from "../../components/contect-form/Form";
import Nav_Bar from "../../components/nav-bar/Nav_Bar";

export const Contactus = () => {
  return (
    <div>
      <Nav_Bar backgroundColor={"#87098a"} />
      
      <Form />
    </div>
  );
};
