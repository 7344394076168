import React from "react";

const Ecommerce_sites = () => {
  return (
    <div className="mt-5 container">
      <h4 className="text-center">تصميم المتاجر الالكترونيه</h4>
      <div className="projectsBoxes mt-5">
        <div className="projectBox d-flex justify-content-between align-items-center gap-5">
          <div className="content">
            <h4 className="w-50 mb-3">
              ديجيتال تك ستور موقع إلكتروني يقدم مجموعة واسعة من المنتجات
              الرقمية
            </h4>
            <p className="w-75">
              حصل على خدمات برمجة وتصميم مواقع إلكترونية شاملة وابدأ في توسيع
              نطاق نشاطك لتكون الأفضل على مستوى العالم. ونحن نسعى جاهدين لنكون
              وجهتك الأولى لتصميم موقعك الإلكتروني، حيث نقدم لك خدمات برمجة
              وتصميم مواقع احترافية من خلال أفضل المبرمجين ذوي الخبرة والكفاءة،
              لتضمن لك الثقة وجودة الخدمة في آن واحد وسرعة التنفيذ.
            </p>
            <div className="btns w-75 d-flex justify-content-between align-items-center">
              <button
                className="d-flex justify-content-center align-items-center mt-2 rounded-2 px-4 py-1 text-white"
                style={{ backgroundColor: "#3C096C" }}
              >
                اطلب الخدمه الان
              </button>
              <button
                className="d-flex justify-content-center align-items-center mt-2 rounded-2 px-4 py-1 text-white"
                style={{ backgroundColor: "#3C096C" }}
              >
                اطلب الخدمه الان
              </button>
            </div>
          </div>
          <div className="img">
            <img width={400} src="/assets/web/Rectangle 102.png" alt="" />
          </div>
        </div>

        <div className="projectBox d-flex justify-content-between align-items-center gap-5">
          <div className="img">
            <img width={400} src="/assets/web/Rectangle 102.png" alt="" />
          </div>
          <div className="content">
            <h4 className="w-50 mb-3">
              ديجيتال تك ستور موقع إلكتروني يقدم مجموعة واسعة من المنتجات
              الرقمية
            </h4>
            <p className="w-75">
              حصل على خدمات برمجة وتصميم مواقع إلكترونية شاملة وابدأ في توسيع
              نطاق نشاطك لتكون الأفضل على مستوى العالم. ونحن نسعى جاهدين لنكون
              وجهتك الأولى لتصميم موقعك الإلكتروني، حيث نقدم لك خدمات برمجة
              وتصميم مواقع احترافية من خلال أفضل المبرمجين ذوي الخبرة والكفاءة،
              لتضمن لك الثقة وجودة الخدمة في آن واحد وسرعة التنفيذ.
            </p>
            <div className="btns w-75 d-flex justify-content-between align-items-center">
              <button
                className="d-flex justify-content-center align-items-center mt-2 rounded-2 px-4 py-1 text-white"
                style={{ backgroundColor: "#3C096C" }}
              >
                اطلب الخدمه الان
              </button>
              <button
                className="d-flex justify-content-center align-items-center mt-2 rounded-2 px-4 py-1 text-white"
                style={{ backgroundColor: "#3C096C" }}
              >
                اطلب الخدمه الان
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ecommerce_sites;
