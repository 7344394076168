import axios from "axios";
import React, { useEffect, useState } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "./navgationBar.css"

const Nav_Bar = (props) => {
  const push = useNavigate();
  const links = [
    { title: "الرئيسيه", link: "/" },
    { title: "من نحن", link: "/how-we-are" },
    { title: "خدماتنا", link: "/serv" },
    { title: "اعمالنا", link: "/rmoz-projects" },
    // { title: "تواصل معنا", link: "contact-us" },
  ];
  const [data, setData] = useState([]);
  useEffect(() => {
    const getcontent = async () => {
      try {
        const response = await axios.get("https://rmoztec.com/api/public/api/contact-numbers");
        setData(response.data.data);
      } catch (err) {
        console.log(err);
      }
    };
    getcontent();
  }, []);


  return (
    <Navbar
      className="navgationBar py-3"
      expand="lg"
      style={{backgroundColor: props.backgroundColor}}
    >
      <Container className="d-flex justify-content-between justify-content-center">
        <Navbar.Brand>
          <div
            className="logo"
            style={{ cursor: "pointer" }}
            onClick={() => push("/")}
          >
            <img width={120} src="/assets/logoRmoz.png" alt="logoRmoz" />
          </div>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="m-auto">
            {links.map((i) => (
              <Nav.Link className="text-white px-4 link_navbar_toStyles" onClick={() => push(i.link)}>
                {i.title}
              </Nav.Link>
            ))}
          </Nav>
        </Navbar.Collapse>
        <div className="links d-flex flex-column flex-md-row align-items-center text-white gap-2 gap-md-5">
          <div className=" d-flex justify-content-between align-items-center gap-3">
            {data.map((item) => (
              <div className="phones d-flex gap-3">
                <p className="p-0 m-0"> {item.phone}</p>
              </div>
            ))}
            <a
              href="https://wa.me/+966570869472"
              target="blank"
              className="NavcontactWhatsApp"
            >
              <img width={50} src="/assets/whatsapp.gif" alt="whatsapp" />
            </a>
          </div>
          <button
            onClick={() => push("/contact-us")}
            style={{
              fontSize: "19px",
              fontWeight: "bold",
              backgroundColor: "#fffff",
              color: "#87098a",
              border: "none",
              padding: "5px 20px",
              borderRadius: "9px",
            }}
          >
            ابدا مشروعك
          </button>
        </div>
      </Container>
    </Navbar>
  );
};

export default Nav_Bar;